import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import '@assets/css/components/order/success.sass'
import { currencyFormatUS } from '@helpers/string-helper'
import PaymentSuccessCards from '@shared/payment-success-cards'
import CondensedContact from '@components/shared/condensed-contact-links'
import DeliveryProduct from '@components/checkout/checkout-parts/delivery-section/delivery-product'
import { getDateFull } from '@helpers/date'
import RTGLink from '@shared/link'
import { setOrder, setOrderList, setOrderNumber } from '@redux/modules/orderStatus'
import { LoadingSpinner, LoadingSpinnerWrapper } from '@components/shared/LoadingSpinner'
import styled from 'styled-components'
import { getFromBrowserStorage } from '@helpers/storage'
import { DoorwayDeliveryMessage } from '@helpers/delivery'
import { getPaymentType } from './helpers'
import { getOrderOnRefresh } from '../order-payment/helpers'
import PriceAtom from '../../../../@rtg2022/components/atoms/Price/Price'

const AppliedPayments = styled.li`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: column;
    span {
      align-self: flex-end;
    }
  }
`

const OrderSuccess = () => {
  const order = useSelector(state => state.orderStatus.order)
  const orderNumber = useSelector(state => state.orderStatus.orderNumber)
  const orderList = useSelector(state => state.orderStatus.orderList)
  const dispatch = useDispatch()
  const activePayment = getFromBrowserStorage('local', 'activePayment')

  useEffect(() => getOrderOnRefresh({ dispatch, setOrder, setOrderList, setOrderNumber }), [dispatch])

  const creditPayments = order?.Deposits?.Deposit || []
  const financePayments = order?.FinDeposits?.Deposit || []
  const appliedPayments = [...creditPayments, ...financePayments] // may or may not contain current payment
  const previousPayments = appliedPayments.filter(
    item => Number(item.AuthorizedAmount) !== Number(activePayment.authAmount), // if it does, let's remove it
  )
  const previousPaymentsTotal = previousPayments.reduce((acc, curr) => Number(curr.AuthorizedAmount) + acc, 0)
  let todaysPayments = appliedPayments.filter(
    item =>
      item.AuthorizedDate.substring(0, 9) === new Date().toISOString().substring(0, 9) &&
      Number(item.AuthorizedAmount) === Number(activePayment.authAmount),
  )[0]
  /*
   * sometimes the fetched order doesn't contain our most recent payment,
   * in those cases, we use the payment info saved in localStorage as a fallback
   */
  if (!todaysPayments?.AuthorizedAmount) {
    todaysPayments = {
      AuthorizedAmount: Number(activePayment.authAmount),
      paymentType: 'unknown', // without the type, we won't be able to render payment cards
    }
  }
  const remainingBalance = Number(order?.OrderTotal) - previousPaymentsTotal - todaysPayments.AuthorizedAmount

  if (appliedPayments) {
    appliedPayments.forEach(payment => {
      payment.paymentType = getPaymentType(payment)
      payment.authorizedAmount = payment.AuthorizedAmount
    })
  }

  let deliveryDate = order?.DeliveryDate
  if (deliveryDate) {
    deliveryDate = getDateFull(deliveryDate)
  }

  return (
    <>
      {order && orderNumber && orderList?.length && (
        <div className="order-success grid-container">
          <div className="grid-x cell small-12">
            <div className="grid-x cell small-12 order-success-content">
              {order?.LineItems?.LineItem.length > 0 && (
                <div className="grid-x grid-margin-y cell small-12">
                  <div className="grid-x grid-margin-y cell small-12">
                    <div className="cell small-12 large-8">
                      <h1>THANK YOU!</h1>
                      <h2>Your payment is being processed.</h2>
                      <h2>
                        Note: Due to unusually high volume, it may take up to 12 hours to receive your email
                        confirmation.
                      </h2>
                      <div className="confirmation-email">
                        {`A confirmation email will be sent to ${order.EmailAddress}`}
                      </div>
                      <div className="cell small-12 medium-6">
                        <RTGLink
                          className="upsTracking"
                          data={{
                            slug: '/order/details',
                            title: `Order Details`,
                            category: 'Order Details',
                            action: 'click',
                            label: `Order Details`,
                          }}
                        >
                          Click to return to order details for delivery updates and tracking
                        </RTGLink>
                      </div>
                    </div>
                    <div className="cell small-12 medium-12 large-4 order-success-help-contact">
                      <div className="order-success-need-help">Need Help?</div>
                      <CondensedContact />
                    </div>
                  </div>
                  <div className="grid-x grid-margin-y cell small-12 large-8">
                    <div className="grid-x cell small-12 order-success-product-totals card">
                      <div className="cell small-12 order-success-product">
                        <h3>Your Order</h3>
                        {order.LineItems.LineItem.map(item => (
                          <DeliveryProduct
                            key={item.Sku}
                            product={{ sku: item.Sku, title: item.DisplayName }}
                            quantity={item.Quantity}
                            warrantyEnabled={item.WarrantyEnabled}
                            index={1}
                            noImage
                            isStoreSku
                          />
                        ))}
                      </div>
                      <div className="cell small-12 large-6 order-success-totals right">
                        <ul>
                          <li>
                            <span className="">Sub-Total</span>
                            <PriceAtom
                              className="right bold"
                              price={currencyFormatUS(order.OrderTotal - order.TaxAmount - order.DeliveryCharge, true)}
                            />
                          </li>
                          <li>
                            <span className="">Shipping & Delivery</span>
                            <PriceAtom className="right" price={currencyFormatUS(order.DeliveryCharge, true)} />
                          </li>
                          <li>
                            <span className="">Sales Tax</span>
                            <PriceAtom className="right" price={currencyFormatUS(order.TaxAmount, true)} />
                          </li>
                          {previousPayments && (
                            <AppliedPayments>
                              <div>
                                <span>Previous Payment(s)</span>
                              </div>
                              <div>
                                <PriceAtom price={currencyFormatUS(previousPaymentsTotal, true)} />
                              </div>
                            </AppliedPayments>
                          )}
                          {todaysPayments && (
                            <AppliedPayments>
                              <div>
                                <span className="bold">Current Payment</span>
                              </div>
                              <div>
                                <PriceAtom
                                  className="bold"
                                  price={currencyFormatUS(todaysPayments?.AuthorizedAmount, true)}
                                />
                              </div>
                            </AppliedPayments>
                          )}
                          <hr />
                          <li>
                            <span className="bold">Remaining Total</span>
                            <PriceAtom className="right bold" price={currencyFormatUS(remainingBalance, true)} />
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="grid-x cell small-12 order-success-delivery card">
                      <div className="cell small-12">
                        <h3>Delivery</h3>
                        <br />
                        {order?.DeliveryType !== 'O' && order?.DeliveryType !== 'U' && (
                          <div className="cell small-12 medium-6">
                            <strong>Scheduled Delivery Date</strong>
                            <p>
                              {deliveryDate || (
                                <>
                                  Contact Customer Service to Schedule a Delivery Date -{' '}
                                  {order?.storeOrder ? (
                                    <a href="tel:(800)-766-6786">(800)-766-6786</a>
                                  ) : (
                                    <a href="tel:(888)-709-5380">(888)-709-5380</a>
                                  )}
                                </>
                              )}
                            </p>
                          </div>
                        )}
                        <br />
                        {order?.DeliveryType === 'O' && (
                          <div className="cell small-12 medium-6">
                            <strong>Ships direct from vendor.</strong>
                          </div>
                        )}
                        {order?.DeliveryType === 'U' && (
                          <div className="cell small-12 medium-6">
                            <strong>Ships via UPS.</strong>
                          </div>
                        )}
                        {order?.DeliveryType === 'D' && order?.DoorwayDelivery && (
                          <div className="cell small-12 medium-6">
                            <strong>{DoorwayDeliveryMessage.default}.</strong>
                          </div>
                        )}
                        {order?.DeliveryType === 'E' && order?.DoorwayDelivery && (
                          <div className="cell small-12 medium-6">
                            <strong>{DoorwayDeliveryMessage.express}.</strong>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid-x grid-margin-y cell small-12 large-4 order-success-shipping-payment">
                    <div className="cell small-12 card order-success-shipping">
                      <h3>Shipping & Delivery Address</h3>
                      <div>{`${order.Customer.FirstName} ${order.Customer.LastName}`}</div>
                      <div>{order.DeliveryAddress.Address1}</div>
                      <div>{order.DeliveryAddress.Address2}</div>
                      <div>{`${order.DeliveryAddress.City}, ${order.DeliveryAddress.State} ${order.DeliveryAddress.Zip}`}</div>
                      <br />
                      <div>{order.EmailAddress}</div>
                      <div>{order.Customer.PhoneNumber}</div>
                      {order?.Customer?.AltPhoneNumber && (
                        <div>{`Alternate Phone: ${order.Customer.AltPhoneNumber}`}</div>
                      )}
                    </div>
                    <div className="cell small-12 card order-success-payment">
                      <h3>Payment</h3>
                      <h4>Billing Information</h4>
                      {order?.BillingAddress?.Address1 !== '' && (
                        <>
                          <div>{`${order.Customer.FirstName} ${order.Customer.LastName}`}</div>
                          <div>{order.BillingAddress.Address1}</div>
                          <div>{order.BillingAddress.Address2}</div>
                          <div>{`${order.BillingAddress.City}, ${order.BillingAddress.State} ${order.BillingAddress.Zip}`}</div>
                          <br />
                        </>
                      )}
                      {order?.BillingAddress?.Address1 === '' && order?.DeliveryAddress?.Address1 && (
                        <>
                          <div>{`${order.Customer.FirstName} ${order.Customer.LastName}`}</div>
                          <div>{order.DeliveryAddress.Address1}</div>
                          <div>{order.DeliveryAddress.Address2}</div>
                          <div>{`${order.DeliveryAddress.City}, ${order.DeliveryAddress.State} ${order.DeliveryAddress.Zip}`}</div>
                          <br />
                        </>
                      )}
                      {order?.Deposits?.Deposit && <PaymentSuccessCards paymentInfo={[todaysPayments]} />}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="cell small-12">
              <CondensedContact />
            </div>
          </div>
        </div>
      )}
      {(!orderList?.length || !orderNumber || !order) && (
        <LoadingSpinnerWrapper>
          <LoadingSpinner />
        </LoadingSpinnerWrapper>
      )}
    </>
  )
}

OrderSuccess.propTypes = {}

export default OrderSuccess

import * as React from 'react'
import PropTypes from 'prop-types'

/**
 * Renders the Concora logo as an SVG.  This is a rebranding of Genesis
 *
 * @param {object} width - The width of image or defaults to 100%.
 * @returns {JSX.Element} - The rendered SVG element.
 */

const ConcoraLogo = ({ width }) => (
  <svg
    viewBox="0 0 528 188.4"
    style={{
      enableBackground: 'new 0 0 528 188.4',
    }}
    xmlSpace="preserve"
    width={width || '100%'}
  >
    <style type="text/css">{'\n\t.st0{fill:#001689;}\n\t.st1{fill:#F4893D;}\n'}</style>
    <g>
      <path
        className="st0"
        d="M130.4,92.9c-5.2,0-9.9-0.9-14-2.6s-7.6-4.1-10.4-7c-2.8-3-5-6.4-6.5-10.3s-2.3-8-2.3-12.3s0.8-8.4,2.3-12.3 s3.7-7.3,6.5-10.2s6.3-5.2,10.4-7c4.1-1.7,8.8-2.6,14-2.6s9.9,0.9,14.1,2.6c4.1,1.7,7.7,4.1,10.5,7c2.9,2.9,5.1,6.3,6.6,10.2 s2.3,8,2.3,12.3s-0.8,8.4-2.3,12.3s-3.7,7.3-6.6,10.3c-2.9,3-6.4,5.3-10.5,7S135.7,92.9,130.4,92.9z M130.4,75.6 c4.3,0,7.6-1.5,10-4.4c2.3-2.9,3.5-6.4,3.5-10.6c0-4.1-1.2-7.5-3.5-10.5c-2.3-2.9-5.6-4.4-10-4.4c-4.2,0-7.5,1.5-9.8,4.4 s-3.4,6.4-3.4,10.5s1.1,7.7,3.4,10.6S126.2,75.6,130.4,75.6z"
      />
      <path
        className="st0"
        d="M215.7,91.3V55.9c0-3.8-0.9-6.4-2.7-7.9s-4.2-2.2-7.3-2.2c-3,0-5.4,0.6-7.3,1.9c-1.9,1.3-3.4,2.6-4.6,4.1v39.6 h-19.5V30.1h19.5v7.5c1.9-2.2,4.6-4.2,8.1-6.2c3.6-1.9,7.9-2.9,13.2-2.9c6.9,0,12,1.8,15.3,5.5c3.3,3.6,4.9,8.3,4.9,14v43.4 L215.7,91.3L215.7,91.3z"
      />
      <path
        className="st0"
        d="M278.1,92.9c-4.8,0-9.3-0.8-13.3-2.3c-4.1-1.6-7.6-3.8-10.5-6.6c-3-2.8-5.3-6.2-7-10.1s-2.5-8.3-2.5-13.1 s0.8-9.2,2.5-13.1s4-7.3,7-10.1s6.5-5,10.5-6.5c4.1-1.6,8.5-2.3,13.3-2.3c3.3,0,6.3,0.3,9,1s5.1,1.5,7.2,2.6s3.8,2.3,5.3,3.6 c1.5,1.4,2.7,2.7,3.6,4.1l-12.7,11.8c-1.2-1.8-2.7-3.2-4.7-4.2c-1.9-1.1-4.2-1.6-6.8-1.6c-4.1,0-7.5,1.3-10.2,3.9 c-2.7,2.6-4.1,6.3-4.1,10.9c0,4.7,1.4,8.3,4.1,11s6.2,4,10.2,4c2.6,0,4.9-0.6,6.8-1.7s3.5-2.6,4.7-4.2l12.7,11.9 c-0.9,1.4-2.1,2.7-3.6,4.1s-3.3,2.6-5.3,3.6c-2.1,1.1-4.5,1.9-7.2,2.6C284.4,92.5,281.4,92.9,278.1,92.9z"
      />
      <path
        className="st0"
        d="M341.4,92.9c-5.2,0-9.9-0.9-14-2.6s-7.6-4.1-10.4-7c-2.8-3-5-6.4-6.5-10.3s-2.3-8-2.3-12.3s0.8-8.4,2.3-12.3 s3.7-7.3,6.5-10.2s6.3-5.2,10.4-7c4.1-1.7,8.8-2.6,14-2.6s9.9,0.9,14.1,2.6c4.1,1.7,7.7,4.1,10.5,7c2.9,2.9,5.1,6.3,6.6,10.2 s2.3,8,2.3,12.3s-0.8,8.4-2.3,12.3s-3.7,7.3-6.6,10.3s-6.4,5.3-10.5,7C351.3,92,346.6,92.9,341.4,92.9z M341.4,75.6 c4.3,0,7.6-1.5,10-4.4c2.3-2.9,3.5-6.4,3.5-10.6c0-4.1-1.2-7.5-3.5-10.5c-2.3-2.9-5.6-4.4-10-4.4c-4.2,0-7.5,1.5-9.8,4.4 s-3.4,6.4-3.4,10.5s1.1,7.7,3.4,10.6S337.2,75.6,341.4,75.6z"
      />
      <path
        className="st0"
        d="M385.1,91.3V30.1h19.5v7.5c1-1.3,2.2-2.5,3.7-3.6c1.4-1.1,3-2,4.7-2.9c1.7-0.8,3.5-1.5,5.3-2 c1.9-0.5,3.7-0.8,5.5-0.8v18.8c-1-0.3-2.6-0.5-4.8-0.5c-1.3,0-2.6,0.1-4,0.4c-1.4,0.3-2.8,0.6-4.1,1.1c-1.4,0.5-2.6,1.1-3.7,1.8 s-1.9,1.4-2.5,2.3v39.1C404.7,91.3,385.1,91.3,385.1,91.3z"
      />
      <path
        className="st0"
        d="M473.9,91.3v-7.1c-2.5,3-5.3,5.1-8.4,6.5c-3,1.4-6.3,2.1-9.8,2.1c-3.8,0-7.3-0.7-10.6-2.1 c-3.3-1.4-6.1-3.5-8.6-6.2s-4.4-6.1-5.8-10.1s-2.1-8.6-2.1-13.8c0-5,0.7-9.5,2.1-13.4c1.4-4,3.3-7.3,5.7-10.1 c2.4-2.7,5.3-4.9,8.6-6.3c3.3-1.5,6.8-2.2,10.7-2.2c3.4,0,6.6,0.7,9.7,2.1s5.9,3.6,8.3,6.7v-7.2h19.5v61.3h-19.3L473.9,91.3 L473.9,91.3z M462.1,75.6c2.1,0,4.3-0.5,6.5-1.5s4-2.4,5.3-4.1V51.4c-1.3-1.7-3-3-5.3-4c-2.2-1-4.4-1.5-6.5-1.5 c-3.9,0-7.1,1.3-9.6,3.9c-2.5,2.6-3.8,6.2-3.8,10.8s1.3,8.2,3.8,10.9S458.2,75.6,462.1,75.6z"
      />
      <path
        className="st0"
        d="M35.3,35.6c0.9-1.5,1.9-2.8,3-4c2.1-2.2,4.6-3.9,7.5-5.1s6-1.8,9.5-1.8c2,0,4,0.3,5.8,1 c1.9,0.6,3.6,1.5,5.2,2.7c1.6,1.1,3,2.5,4.2,3.9c1.2,1.5,2.2,3.1,2.9,4.8l18.8-9c-1.4-2.8-3.1-5.5-5.2-8.2s-4.7-5.1-7.7-7.2 c-3-2.2-6.5-3.9-10.5-5.2s-8.5-2-13.6-2c-6.5,0-12.6,1.1-18.1,3.2c-5.6,2.1-10.4,5.1-14.5,8.9c-2,1.9-3.8,4-5.4,6.2L35.3,35.6z"
      />
      <path
        className="st0"
        d="M70.6,66c-1.2,1.5-2.6,2.8-4.2,3.9s-3.3,2-5.2,2.7c-1.9,0.6-3.8,1-5.8,1c-3.5,0-6.6-0.6-9.5-1.9 s-5.4-3-7.5-5.2c-0.7-0.7-1.3-1.4-1.8-2.2l-18,11.9c1.3,1.6,2.7,3.2,4.2,4.6c4.1,3.8,8.9,6.8,14.5,8.9s11.6,3.2,18.1,3.2 c5.1,0,9.6-0.7,13.6-2c4-1.4,7.5-3.1,10.5-5.3c3-2.2,5.6-4.6,7.7-7.3c2.1-2.7,3.8-5.4,5.2-8.1l-18.8-8.9C72.8,63,71.9,64.5,70.6,66 z"
      />
      <polygon className="st1" points="40.4,50.3 6,27.6 6,73  " />
      <path
        className="st1"
        d="M272,182.7c-3.8,0-7.3-0.7-10.5-2.1c-3.2-1.4-5.9-3.3-8.1-5.8s-4-5.3-5.2-8.6s-1.9-6.8-1.9-10.7 c0-3.8,0.6-7.4,1.9-10.7c1.2-3.3,3-6.1,5.2-8.5s4.9-4.3,8.1-5.7s6.7-2.1,10.5-2.1c4.7,0,8.5,0.9,11.5,2.6c2.9,1.7,5.4,3.8,7.4,6.4 l-5.3,4.8c-1.7-2.3-3.6-4-5.8-5.1c-2.2-1-4.6-1.5-7.3-1.5c-2.7,0-5.2,0.5-7.4,1.5c-2.2,1-4.1,2.4-5.6,4.2c-1.6,1.8-2.8,3.9-3.6,6.3 c-0.8,2.4-1.2,5-1.2,7.9c0,2.8,0.4,5.5,1.2,7.9c0.8,2.5,2,4.6,3.6,6.3c1.6,1.8,3.4,3.2,5.6,4.2s4.7,1.5,7.4,1.5 c5.4,0,9.8-2.2,13.1-6.7l5.3,4.9c-2,2.6-4.5,4.7-7.4,6.4C280.5,181.8,276.7,182.7,272,182.7z"
      />
      <path
        className="st1"
        d="M299.8,181.4v-51.5h8v8.2c2.1-2.7,4.5-4.9,7.5-6.7c2.9-1.8,6.1-2.7,9.7-2.7v8.1c-0.5-0.1-1-0.1-1.5-0.2 c-0.5,0-1-0.1-1.6-0.1c-1.3,0-2.6,0.2-4,0.7s-2.7,1.1-4,1.9c-1.3,0.8-2.5,1.7-3.6,2.6c-1.1,1-1.9,2-2.5,3v36.5h-8V181.4z"
      />
      <path
        className="st1"
        d="M355.5,182.7c-3.8,0-7.4-0.7-10.6-2s-6-3.2-8.3-5.5c-2.3-2.4-4.1-5.2-5.4-8.5s-2-7-2-11 c0-3.7,0.6-7.2,1.9-10.5s3-6.2,5.3-8.6c2.2-2.4,4.9-4.3,8-5.8c3.1-1.4,6.5-2.1,10.2-2.1c3.9,0,7.4,0.7,10.4,2.1 c3.1,1.4,5.6,3.4,7.8,5.9c2.1,2.5,3.7,5.4,4.8,8.8c1.1,3.4,1.7,7,1.7,10.8v2h-41.6c0.1,2.4,0.7,4.7,1.6,6.9s2.2,4,3.7,5.6 c1.6,1.6,3.4,2.9,5.6,3.8c2.2,1,4.7,1.4,7.5,1.4c3,0,5.9-0.6,8.7-1.7c2.8-1.1,5.2-2.8,7.3-4.9l3.8,5.2c-2.6,2.6-5.6,4.5-9.1,5.9 C363.5,182,359.7,182.7,355.5,182.7z M371.3,152.3c0-1.9-0.3-3.9-1-5.9s-1.7-3.8-3.1-5.5s-3.1-3-5.3-4.1s-4.7-1.6-7.6-1.6 c-2.8,0-5.2,0.5-7.2,1.5c-2.1,1-3.8,2.4-5.2,4c-1.4,1.7-2.5,3.5-3.2,5.5s-1.2,4-1.2,5.9h33.8L371.3,152.3L371.3,152.3z"
      />
      <path
        className="st1"
        d="M426.5,181.4v-7.7c-1.9,2.6-4.4,4.8-7.4,6.4c-3,1.7-6.3,2.5-10,2.5c-3.3,0-6.4-0.6-9.2-1.9 c-2.8-1.2-5.2-3-7.2-5.3s-3.6-5.1-4.7-8.5c-1.1-3.3-1.7-7.1-1.7-11.3c0-4.1,0.6-7.8,1.7-11.2c1.1-3.3,2.7-6.2,4.7-8.5 s4.4-4.2,7.2-5.4c2.8-1.3,5.9-1.9,9.2-1.9c3.5,0,6.7,0.8,9.7,2.4c3,1.6,5.6,3.8,7.6,6.7v-27.4h8v71.1H426.5z M411.2,175.5 c3.2,0,6.2-0.8,9-2.4c2.8-1.6,4.9-3.5,6.3-5.8v-23.2c-1.4-2.3-3.5-4.2-6.3-5.9c-2.8-1.6-5.8-2.5-9-2.5c-2.6,0-5,0.5-7,1.5 c-2.1,1-3.8,2.4-5.2,4.2c-1.4,1.8-2.5,3.9-3.3,6.3c-0.7,2.5-1.1,5.1-1.1,7.9c0,2.8,0.4,5.5,1.1,7.9s1.8,4.5,3.3,6.3 c1.4,1.8,3.2,3.2,5.2,4.2S408.6,175.5,411.2,175.5z"
      />
      <path
        className="st1"
        d="M452.2,122.6c-1.5,0-2.8-0.5-3.8-1.5c-1.1-1-1.6-2.3-1.6-3.8s0.5-2.8,1.6-3.8c1.1-1.1,2.3-1.6,3.8-1.6 s2.8,0.5,3.8,1.6s1.5,2.3,1.5,3.8s-0.5,2.8-1.5,3.8C455,122,453.7,122.6,452.2,122.6z M448.2,181.4v-51.5h8v51.5H448.2z"
      />
      <path
        className="st1"
        d="M485.7,182.7c-3.8,0-6.7-1-8.6-3.1s-2.9-5.1-2.9-8.9V137h-8.6v-7h8.5v-14.1h8.1V130h10.4v7h-10.4v32 c0,1.9,0.4,3.5,1.3,4.7s2.1,1.9,3.8,1.9c1.2,0,2.3-0.2,3.2-0.7c0.9-0.5,1.7-1,2.2-1.5l2.3,6.1c-1,1-2.3,1.8-3.8,2.4 C489.8,182.4,487.9,182.7,485.7,182.7z"
      />
      <g id="Group_47162" transform="translate(1146.895 450.137)">
        <path id="Path_34253" className="st0" d="M-640.7-358.6v-6.9h-2.7v-1.5h7.3v1.5h-2.7v6.9H-640.7z" />
        <path
          id="Path_34254"
          className="st0"
          d="M-635.1-358.6v-8.5h1.6l2.9,6.6h-0.4l2.9-6.6h1.6v8.5h-1.7v-5.8h0.5l-2.6,5.8h-1.1l-2.6-5.8 h0.5v5.8H-635.1z"
        />
      </g>
    </g>
  </svg>
)

ConcoraLogo.propTypes = {
  width: PropTypes.number,
}

export default ConcoraLogo

ConcoraLogo.propTypes = {
  width: PropTypes.object,
}

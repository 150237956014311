import React from 'react'
import classNames from 'classnames'
import { objectOf, any, number, string, bool, oneOfType } from 'prop-types'
import { decodeHtml } from '@helpers/string-helper'
import { productUrl } from '@helpers/route'
import { getProductDisplayImage } from '@helpers/product'
import RTGLink from '../../../shared/link'
import { DeliveryProductWrapper } from './styles'

const DeliveryProduct = ({
  product,
  productCount,
  quantity,
  index,
  noImage,
  requiredAddon,
  warrantyEnabled,
  isStoreSku,
}) => {
  let route = null
  if (product && product.route) route = product.route
  return (
    <DeliveryProductWrapper
      key={product.sku}
      className={classNames({
        separator: index !== productCount - 1,
      })}
    >
      <div className="product-container grid-x">
        {!noImage && (
          <div className="product-image-container small-2 large-4">
            {!requiredAddon && product.primary_image && (
              <RTGLink
                data={{
                  slug: productUrl(route),
                  altDesc: product.title ? product.title : '',
                }}
                aria-hidden="true"
                tabIndex="-1"
              >
                <img
                  src={`${getProductDisplayImage(product)}${product.sku !== '83333333' ? '&h=150' : ''}`}
                  alt=""
                  role="presentation"
                  aria-hidden="true"
                  className="product-image"
                />
              </RTGLink>
            )}
          </div>
        )}
        <div
          className={classNames('product-info', {
            'small-6 large-5': !noImage,
            'small-9': noImage,
          })}
        >
          <RTGLink
            data={{
              slug: productUrl(route),
              altDesc: product.title ? product.title : '',
            }}
            disabled={productUrl(route).includes('productnull') || requiredAddon || isStoreSku}
          >
            <p>
              {product.title ? decodeHtml(product.title) : `SKU: ${product.sku}`}{' '}
              {warrantyEnabled && <span className="warranty-label">+Furniture Protection Plan</span>}
            </p>
          </RTGLink>
        </div>
        <div className="quantity small-2">QTY: {` ${quantity}`}</div>
      </div>
    </DeliveryProductWrapper>
  )
}

DeliveryProduct.propTypes = {
  product: objectOf(any),
  productCount: oneOfType([string, number]),
  quantity: number,
  index: number,
  requiredAddon: bool,
  warrantyEnabled: bool,
  noImage: bool,
  isStoreSku: bool,
}

export default DeliveryProduct

export const getPaymentType = dep => {
  switch (dep.CashCode) {
    case 'MC':
      return 'CYBERV3'
    case 'VA':
      return 'CYBERV3'
    case 'DV':
      return 'CYBERV3'
    case 'AMX':
      return 'CYBERV3'
    case 'RGE':
      return 'FIN'
    case 'GC':
      return 'GIFT'
    case 'PAL':
      return 'PALV2'
    case 'AFF':
      return 'AFF'
    default:
      return ''
  }
}

export default getPaymentType

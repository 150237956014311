import React from 'react'
import Helmet from 'react-helmet'
import Layout from '@components/layout'
import OrderSuccess from '@components/order/status/order-success'

const Success = () => (
  <Layout>
    <Helmet title="Order Thank You - Rooms To Go" />
    <OrderSuccess />
  </Layout>
)

export default Success

import styled from 'styled-components'
import spinnerSVG from './loader-dark.svg'

export const LoadingSpinnerWrapper = styled.div`
  height: 4rem;
  width: 100%;
  margin: 1.5rem 0 0 0;
`

export const LoadingSpinner = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${spinnerSVG});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

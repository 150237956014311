import React from 'react'
import { array } from 'prop-types'
import { Grid } from '@material-ui/core'
import ConcoraLogo from '@shared/svgs/concora-logo'
import MaskedCredit from '../checkout/masked-credit'

const paymentLables = {
  AFF: 'Affirm:',
  APP: 'Apple Pay:',
  CYBERV3: 'Credit Card:',
  DBUY: 'Rooms To Go Finance:',
  FIN: 'Rooms To Go Finance:',
  GEN: ': $',
  GIFT: 'Gift Card:',
  KLN: 'Klarna:',
  PALV2: 'Paypal:',
  VISA: 'Visa Checkout:',
}

const PaymentSuccessCards = ({ paymentInfo = [] }) => {
  if (!paymentInfo?.length) return null

  return (
    <Grid container direction="row" spacing={3} alignItems="flex-end">
      {paymentInfo.map(payment => {
        const payType = payment?.paymentType ?? ''
        let amount = payment?.authorizedAmount ?? ''
        if (typeof amount === 'number') {
          amount = amount.toFixed(2)
        }

        if (payType === 'GEN') {
          return (
            <Grid item key={payType}>
              <ConcoraLogo width={250} />
              <Grid item>
                <MaskedCredit lastFour={(payment?.paymentProperties?.accountNumber ?? '111111111111xxxx').slice(12)} />
                {`${paymentLables[payType]}${amount}`}
              </Grid>
            </Grid>
          )
        }

        return (
          <Grid item key={payType}>
            <p>{paymentLables[payType]}</p>
            <p>{`$${amount}`}</p>
          </Grid>
        )
      })}
    </Grid>
  )
}

PaymentSuccessCards.propTypes = {
  paymentInfo: array,
}

export default PaymentSuccessCards

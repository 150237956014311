import React from 'react'
import { styled, Grid, Typography } from '@mui/material'
import PhoneIcon from '@mui/icons-material/Phone'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import RTGLink from './link'

const Contact = styled('div')(() => ({
  padding: '15px',
}))

const ContactTitleContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  padding: '1rem 0rem 0.25rem 0rem',
  fontSize: '18px',
}))

const ContactTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.dark,
}))

const LinkContainer = styled(Grid)(({ theme }) => ({
  padding: '0 15%',
}))

const StyledRTGLink = styled(RTGLink)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  color: theme.palette.primary.dark,
  fontSize: '12px',
}))

const CondensedContactLinks = () => (
  <Contact>
    <ContactTitleContainer>
      <ContactTitle>Contact Us</ContactTitle>
    </ContactTitleContainer>

    <LinkContainer container justifyContent="space-between">
      <Grid item>
        <StyledRTGLink
          data={{
            slug: '/customer-service/common-questions',
            category: 'cart/checkout',
            action: 'FAQ click',
            label: 'FAQ',
          }}
        >
          <QuestionMarkIcon sx={{ fontSize: 28 }} aria-hidden="true" role="presentation" />
          FAQ
        </StyledRTGLink>
      </Grid>
      <Grid item>
        <StyledRTGLink
          data={{
            url: 'tel:18887095380',
            category: 'cart/checkout',
            action: 'Call click',
            label: 'Call',
          }}
        >
          <PhoneIcon sx={{ fontSize: 28 }} aria-hidden="true" role="presentation" />
          Phone
        </StyledRTGLink>
      </Grid>
      <Grid item>
        <StyledRTGLink
          data={{
            url: 'mailto:internetSalesSupport@roomstogo.com',
            category: 'cart/checkout',
            action: 'Email click',
            label: 'Email',
          }}
        >
          <MailOutlineIcon sx={{ fontSize: 28 }} aria-hidden="true" role="presentation" />
          Email <span className="hide508">Internet Sales Support</span>
        </StyledRTGLink>
      </Grid>
    </LinkContainer>
  </Contact>
)

export default CondensedContactLinks

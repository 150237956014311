import React from 'react'
import { oneOfType, string, number, bool } from 'prop-types'

const MaskedCredit = ({ lastFour, oneX }) => <>{`${oneX ? 'X-' : 'xxxxxxxxxxxx'}${lastFour}`}</>

MaskedCredit.propTypes = {
  lastFour: oneOfType([string, number]),
  oneX: bool,
}

export default MaskedCredit

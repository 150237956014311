/* eslint-disable import/prefer-default-export */
import React from 'react'
import { some } from 'lodash'
import { DOORWAY_DELIVERY_FREE_SHIPPING_THRESHOLD } from '@components/checkout/checkout-parts/delivery-section/constants'
import { getFromBrowserStorage } from './storage'

export const getDeliveryDate = deliveryInfo => {
  const storageDeliveryInfo = getFromBrowserStorage('session', deliveryInfo)
  let newDeliveryInfo = deliveryInfo
  if (
    deliveryInfo &&
    deliveryInfo.deliveryDate.readable === '' &&
    storageDeliveryInfo &&
    storageDeliveryInfo.deliveryDate &&
    storageDeliveryInfo.deliveryDate.readable !== ''
  ) {
    newDeliveryInfo = storageDeliveryInfo
  }
  if (newDeliveryInfo && newDeliveryInfo.dates.length > 0) {
    let newStoredDate = newDeliveryInfo.dates[0]
    let expressDate
    if (newDeliveryInfo.pickup) {
      const [storedDate] = newDeliveryInfo.dates.filter(date => date.isPickup)[0]
      newStoredDate = storedDate
    } else {
      const expressDates = newDeliveryInfo.dates.filter(date => date.isExpressDelivery)
      expressDate = expressDates.length > 0 ? expressDates[0] : null
      const [storedDate] = newDeliveryInfo.dates.filter(date => date.isStandardDelivery && !date.isExpressDelivery)[0]
      newStoredDate = storedDate
    }
    const selectedDate = newDeliveryInfo.deliveryDate.date
    if (
      newDeliveryInfo.deliveryDate.readable !== '' &&
      selectedDate >= ((expressDate && expressDate.date) || newStoredDate.date)
    ) {
      return {
        readable: newDeliveryInfo.deliveryDate.readable,
        date: selectedDate,
      }
    }
    return {
      readable: newStoredDate.readable,
      date: newStoredDate.date,
    }
  }
  return {
    readable: 'No delivery dates avaliable.',
    date: new Date().toISOString().split('T')[0],
  }
}

export const upsDeliveryMessage =
  'Please check your Order confirmation email for the delivery estimates or call Customer Service at (800)766-6786'

export const vendorDeliveryMessage = 'Ships direct from vendor.'

const doorwayMessage =
  'Placed inside the entryway or garage. Does not include professional assembly or removal of packaging.'

export const DoorwayDeliveryMessage = {
  default: 'Doorway Delivery',
  express: 'Express Doorway Delivery',
  free: 'Free Doorway Delivery',
}

export const PremiumDeliveryMessage = {
  default: 'Premium Delivery and Set-up',
  express: 'Express Premium Delivery and Set-up',
}

/**
 *
 * @param {string} deliveryZone The delivery zone of current user
 * @param {array} lineItems lineItems of  the current order object
 * @returns {bool} whether to show doorway delivery option or not
 */
export const showDoorwayDeliveryOption = (deliveryZone, lineItems) =>
  (deliveryZone === 'A' || deliveryZone === 'B' || deliveryZone === 'C') &&
  Array.isArray(lineItems) &&
  lineItems.some(item => item.deliveryType === 'D' || item.deliveryType === 'K')

export const getDeliveryMessagingByOrder = (isExpress, isDoorwayDelivery, isPickup) => {
  // Pick-up
  if (isPickup) return { label: 'Pick Up From ', message: '' }

  //  Doorway Delivery
  if (isDoorwayDelivery && isExpress) return { label: DoorwayDeliveryMessage.express, message: doorwayMessage }
  if (isDoorwayDelivery) return { label: DoorwayDeliveryMessage.default, message: doorwayMessage }

  // Professional Delivery
  if (isExpress) return { label: 'Express Premium Delivery and Set-Up', message: '4 hour delivery window.' }
  return { label: 'Premium Delivery and Set-Up', message: '4 hour delivery window.' }
}

export const getDeliveryMessagingByDeliveryType = type => DeliveryTypes[type]

export const DeliveryTypes = {
  C: {
    // not offered online
    label: '',
    message: '',
  },
  D: {
    // Standard Delivery - Adults
    label: 'Premium Delivery and Set-Up',
    message: '4 hour delivery window.',
    doorwayLabel: DoorwayDeliveryMessage.default,
    doorwayMessage,
  },
  E: {
    // Express Delivery
    label: 'Express Premium Delivery and Set-Up',
    message: '4 hour delivery window.',
    doorwayLabel: DoorwayDeliveryMessage.express,
    doorwayMessage,
  },
  K: {
    // Standard Delivery - Kids
    label: 'Premium Delivery and Set-Up',
    message: '4 hour delivery window.',
    doorwayLabel: DoorwayDeliveryMessage.default,
    doorwayMessage,
  },
  O: {
    // Vendor direct shipments
    label: 'Shipping directly from vendor',
    message: 'Vendor direct shipping',
  },
  P: {
    // Pick up order from Distribution Center
    label: 'Pick Up From ',
    message: '',
  },
  T: {
    // USPS orders. Only T items online are gift cards
    label: 'Delivery via USPS',
    message: 'Delivery to billing address only.',
  },
  U: {
    // UPS orders
    label: 'Ships via UPS',
    message: '',
  },
  X: {
    // stands for export, treated as type D
    label: 'Premium Delivery and Set-Up',
    message: '4 hour delivery window',
    doorwayLabel: DoorwayDeliveryMessage.default,
    doorwayMessage,
  },
  Z: {
    // remote pick up order for west Texas customers, not used for online orders (pick-ups are from stores not Distribution Centers)
    label: '',
    message: '',
  },
}

/**
 * @function shouldShowAdditionalInstructions
 * @param {object} order
 * @return {boolean} true if any item has one of the required delivery types
 */
export const shouldShowAdditionalInstructions = order => {
  // * delivery types that should display additional instructions only *
  const deliveryMethodsAccepted = ['k', 'K', 'd', 'D', 'e', 'E']
  if (order?.lineItems?.length > 0) {
    return some(order.lineItems, item => deliveryMethodsAccepted.includes(item.deliveryType))
  }
  return false
}

export const getDeliveryLabel = (type, isDoorway) => {
  if (isDoorway && DeliveryTypes?.[type]?.doorwayLabel) {
    return getDeliveryMessagingByDeliveryType(type)?.doorwayLabel
  }
  return DeliveryTypes?.[type]?.label
}

export const renderDoorwayDelivery = (order, doorwayDelivery, isNonRTG) => {
  if (doorwayDelivery) {
    if ((order.total <= DOORWAY_DELIVERY_FREE_SHIPPING_THRESHOLD && !order.isExpress) || isNonRTG)
      return (
        <>
          <span>FREE </span>Doorway Delivery
        </>
      )
    return `${order.isExpress ? 'Express ' : ''}Doorway Delivery`
  }

  return `${order.isExpress ? 'Express Premium' : 'Premium Rooms To Go'} Delivery and Set-Up`
}
